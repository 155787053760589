<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Detalles de promoción</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <!--Volume-->
      <template v-if="promotion.type.value==='volume'">
        <div class="p-6">
          <!--Products-->
          <div v-if="promotion.target==='product'">
            <label class="vs-select--label" for="productVolume">Selecciona producto</label>
            <v-select id="productVolume" label="label" :options="products"
                      name='product'
                      v-model="promotion.detail.product"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('product')">{{ errors.first('product') }}</span>
          </div>
          <!--End-->
          <!--Categories-->
          <div v-if="promotion.target==='category'">
            <label class="vs-select--label mt-5" for="categoryVolume">Selecciona categoría</label>
            <v-select id="categoryVolume" label="name" :options="categories"
                      name='category'
                      v-model="promotion.detail.category"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('category')">{{ errors.first('category') }}</span>
          </div>
          <!--End-->
          <!-- Amount -->
          <vs-input label="Posterior a" v-model.number="promotion.detail.quantity"
                    class="w-full mt-5" name="quantity"
                    v-validate="'required|min_value:0'" type="number"/>
          <span class="text-danger text-sm"
                v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
          <!--End-->
          <!-- Gift -->
          <vs-input label="Obsequio" v-model.number="promotion.detail.gift"
                    class="w-full mt-5" name="gift" id="gift"
                    v-validate="'required|min_value:0'" type="number"/>
          <span class="text-danger text-sm"
                v-show="errors.has('gift')">{{ errors.first('gift') }}</span>
          <!--End-->
        </div>
      </template>
      <!---End Volume-->
      <!--Percentage-->
      <template v-else-if="promotion.type.value==='percentage'">
        <div class="p-6">
          <!--Products-->
          <div v-if="promotion.target==='product'">
            <label class="vs-select--label mt-5" for="productPercentage">Selecciona producto</label>
            <v-select id="productPercentage" label="label" :options="products"
                      name='product'
                      v-model="promotion.detail.product"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('product')">{{ errors.first('product') }}</span>
          </div>
          <!--End-->
          <!--Categories-->
          <div v-if="promotion.target==='category'">
            <label class="vs-select--label mt-5" for="categoryPercentage">Selecciona categoría</label>
            <v-select id="categoryPercentage" label="name" :options="categories"
                      name='category'
                      v-model="promotion.detail.category"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('category')">{{ errors.first('category') }}</span>
          </div>
          <!--End-->
          <!-- Quantity -->
          <vs-input label="Posterior a" v-model.number="promotion.detail.quantity"
                    class="w-full mt-5" name="quantity"
                    v-validate="'required|min_value:0'" type="number"/>
          <span class="text-danger text-sm"
                v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
          <!--End-->
          <!-- Percentage -->
          <vs-input label="Porcentaje" v-model.number="promotion.detail.percentage"
                    class="w-full mt-5" name="percentage" id="percentage"
                    v-validate="'min_value:0'" type="number"/>
          <span class="text-danger text-sm"
                v-show="errors.has('percentage')">{{ errors.first('percentage') }}</span>
          <!--End-->
        </div>
      </template>
      <!---End Percentage-->
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Datepicker from 'vuejs-datepicker'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'

let _ = require('lodash')

import { db, FieldValue, auth } from '@/firebase/firebaseConfig'

export default {
  name: 'PromotionDetail',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    },
    products: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker,
    vSelect
  },
  mixins: [trimString],
  data () {
    return {
      promotion: {
        type: {
          value: ''
        }
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.promotion = _.cloneDeep(this.data)
        if (!this.promotion.detail) {
          this.promotion.detail = {
            type: {},
            detail: {
              quantity: 0,
              gift: 0
            }
          }
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Update data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        console.log(result)
        if (result) {
          this.progress = true

          // Save in firebase
          let obj = {
            ...this.promotion
          }
          await db.collection('promotions').doc(this.promotion.id).update({
            detail: obj.detail,
            uid: auth.currentUser.uid,
            updatedAt: FieldValue.serverTimestamp()
          })

          this.$emit('update', obj)
          this.$emit('closeSidebar')

          this.$vs.notify({
            color: 'success',
            title: 'Promoción',
            text: 'Promoción modificada correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
