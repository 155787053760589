import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

// Initialize Firebase
let config = {
  apiKey: 'AIzaSyAugOu14jOhqzHOmzi8q1tj0HClh9iMI0g',
  authDomain: 'chronicles-9f908.firebaseapp.com',
  projectId: 'chronicles-9f908',
  storageBucket: 'chronicles-9f908.appspot.com',
  messagingSenderId: '440344242164',
  appId: '1:440344242164:web:0246d41f74a50cb150fba1',
  measurementId: 'G-FSQ7QQWBP2'
}

firebase.initializeApp(config)
firebase.analytics()

// utils
const db = firebase.firestore()
const storage = firebase.storage()
const FieldValue = firebase.firestore.FieldValue
const Timestamp = firebase.firestore.Timestamp
const auth = firebase.auth()

export {
  db,
  auth,
  FieldValue,
  Timestamp,
  storage
}

