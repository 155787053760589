<template>
  <div id="invoice-page">
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>Detalle de orden {{ order.id.substr(0, 6).toUpperCase() }} / {{ getStateText(order.state) }}</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false"
                      class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">
          <div class="vx-row">
            <div class="vx-col w-1/2 md:w-1/3 xl:w-1/3">
              <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="DollarSignIcon"
                  :statistic="order.total"
                  statisticTitle="Monto total"/>
            </div>

            <div class="vx-col w-1/2 md:w-1/3 xl:w-1/3">
              <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="DollarSignIcon"
                  :statistic="order.delivery"
                  statisticTitle="Costo de delivery"
                  color="warning"/>
            </div>

            <div class="vx-col w-1/2 md:w-1/3 xl:w-1/3">
              <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="ShoppingCartIcon"
                  statisticTitle="Productos vendidos"
                  :statistic="order.products.reduce((a, c) => { return a+c.quantity}, 0)"
                  color="success"/>
            </div>

          </div>

          <v-select :reduce="state => state.value" :options="states" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="order.state"
                    class="mt-1 w-full col-span-5"/>

          <vs-input :disabled="true" name="observation" label="Observación"
                    v-model="order.observation"
                    class="mt-5 w-full"/>

          <div class="grid grid-cols-3 gap-3">
            <div class="col-span-1">
              <!-- Client name -->
              <vs-input disabled="true" label="Nombre y apellidos"
                        :value="order.name + ' '+ order.lastName"
                        class="mt-5 w-full"/>
            </div>
            <div class="col-span-1">
              <!-- Client phone -->
              <vs-input disabled="true" label="Telefono" :value="order.phone"
                        class="mt-5 w-full"/>
            </div>
            <div class="col-span-1">
              <!-- Client email -->
              <vs-input disabled="true" label="Email" :value="order.email"
                        class="mt-5 w-full"/>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-3">
            <div class="col-span-1">
              <!-- Type document -->
              <vs-input disabled="true" label="Tipo de documento"
                        :value="order.typeDocument"
                        class="mt-5 w-full"/>
            </div>
            <div class="col-span-1">
              <!-- Document -->
              <vs-input disabled="true" label="Documento" :value="order.document"
                        class="mt-5 w-full"/>
            </div>
          </div>

          <div class="mt-5">
            <vs-input label="Dirección" style="width: 100%" disabled="true" type="text"
                      v-model="order.address"/>
          </div>

          <div class="grid grid-cols-3 gap-3">
            <div class="col-span-1">
              <!-- Department -->
              <vs-input disabled="true" label="Departamento" :value="order.departmentName.nomDepartamento"
                        class="mt-5 w-full"/>
            </div>

            <div class="col-span-1">
              <!-- Province -->
              <vs-input disabled="true" label="Provincia" :value="order.provinceName.nomProvincia"
                        class="mt-5 w-full"/>
            </div>

            <div class="col-span-1">
              <!-- District -->
              <vs-input disabled="true" label="Distrito" :value="order.districtName.nomDistrito"
                        class="mt-5 w-full"/>
            </div>
          </div>

          <div>
          </div>

          <div class="mt-5">
            <vs-table style="padding-bottom: 8rem" class="mt-5" :data="order.products">

              <template slot="thead">
                <vs-th>Nombre</vs-th>
                <vs-th>SKU</vs-th>
                <vs-th>Precio</vs-th>
                <vs-th>Cantidad</vs-th>
                <vs-th>Total</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td>
                    {{ tr.name }} - {{ tr.color ? tr.color.name : '' }} - {{ tr.size.size }} <br>
                  </vs-td>

                  <vs-td>
                    {{ tr.sku }}
                  </vs-td>

                  <vs-td>
                    S/ {{ tr.priceDiscount.toFixed(2) }}
                  </vs-td>

                  <vs-td>
                    <template>
                      <vs-input disabled="true" v-model="tr.quantity"/>
                    </template>
                  </vs-td>

                  <vs-td>
                    S/ {{ (tr.priceDiscount * tr.quantity).toFixed(2) }}
                  </vs-td>

                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </component>

      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button class="mr-6" @click="submitData"
                   :disabled="!isOrderFormValid || progress || preState==='rejected' || preState==='delivered'">
          Guardar
        </vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
        <div v-if="progress" style="margin-top: 1rem; width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import StatisticsCardLine from '../../components/statistics-cards/StatisticsCardLine'

let _ = require('lodash')

import { db, FieldValue, auth } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    },
    products: {
      type: Array,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    provinces: {
      type: Array,
      required: true
    },
    districts: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    StatisticsCardLine
  },
  data () {
    return {
      order: {
        client: {},
        total: 0,
        delivery: 0,
        products: [],
        id: '',
        address: {
          departmentName: {},
          provinceName: {},
          districtName: {}
        }
      },
      quantityProducts: 0,
      preState: '',
      states: [
        { label: 'Sin confirmar', value: 'hold' },
        { label: 'Aprobado', value: 'approved' },
        { label: 'Rechazado', value: 'rejected' },
        { label: 'Cancelado', value: 'cancel' },
        { label: 'Entregado', value: 'delivered' }
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  mounted () {
    this.$emit('setAppClasses', 'invoice-page')
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.order = _.cloneDeep(this.data)
        this.order.departmentName = this.departments.find(d => d.codDepartamento === this.order.department)
        this.order.provinceName = this.provinces.find(p => p.codProvincia === this.order.province)
        this.order.districtName = this.districts.find(d => d.codDistrito === this.order.district)
        this.preState = this.order.state
        this.quantityProducts = 0
        this.order.products.forEach((p) => {
          console.log(p.quantity)
          this.quantityProducts += p.quantity
        })
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isOrderFormValid () {
      if (this.order.products)
        return this.order.products.length > 0
      else return false
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Update data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        this.progress = true

        // Update order
        let orderRef = db.collection('orders').doc(this.order.id)

        //Clone to remove the id
        let cloneOrder = _.cloneDeep(this.order)
        delete cloneOrder.id
        delete cloneOrder.createdAt
        delete cloneOrder.departmentName
        delete cloneOrder.provinceName
        delete cloneOrder.districtName

        // Update in firestore
        await orderRef.update({
          ...cloneOrder,
          updatedAt: FieldValue.serverTimestamp(),
          uid: auth.currentUser.uid
        })
        let obj = {
          ...this.order
        }
        this.$emit('update', obj)
        this.$emit('closeSidebar')
        this.$vs.notify({
          color: 'success',
          title: 'Orden',
          text: 'Orden actualizada.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    calculateTotal () {
      return new Promise((resolve, reject) => {
        let subTotal = 0
        this.order.products.forEach((p) => {
          subTotal += (p.priceDiscount * p.quantity)
        })
        if (subTotal === 0 || subTotal < 0) {
          reject(new Error('Subtotal 0'))
        }
        resolve(subTotal)
      })
    },
    deleteProduct (product) {
      if (this.order.products.length > 1) {
        this.order.products.forEach((p, index) => {
          if (p.id === product.id) {
            this.order.products.splice(index, 1)
          }
        })
      } else {
        this.$vs.notify({
          color: 'warning',
          title: 'Orden',
          text: 'No se pueden eliminar todos los productos.'
        })
      }
    },
    getStateText (value) {
      if (value === 'hold') return 'Sin confirmar'
      if (value === 'approved') return 'Aprobado '
      if (value === 'rejected') return 'Rechazado '
      if (value === 'cancel') return 'Cancelado '
      if (value === 'delivered') return 'Entregado'
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 950px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
