<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Direcciones de cliente</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">

        <vs-table :data="addresses">

          <template slot="thead">
            <vs-th>Dirección</vs-th>
            <vs-th>Referencia</vs-th>
            <vs-th>Departamento</vs-th>
            <vs-th>Provincia</vs-th>
            <vs-th>Distrito</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td>
                {{ tr.address }}
              </vs-td>

              <vs-td>
                {{ tr.reference }}
              </vs-td>

              <vs-td>
                {{ tr.departmentName }}
              </vs-td>

              <vs-td>
                {{ tr.provinceName }}
              </vs-td>

              <vs-td>
                {{ tr.districtName }}
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import { db } from '@/firebase/firebaseConfig'

import departments from '../../data/ubigeo/departments.json'
import provinces from '../../data/ubigeo/province.json'
import districts from '../../data/ubigeo/district.json'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    VuePerfectScrollbar,
  },
  data () {
    return {
      client: {},
      addresses: [],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      departments: departments,
      provinces: provinces,
      districts: districts
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      this.client = {
        ...this.data
      }
      this.loadAddresses()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any() && this.addresses.length > 0
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        this.progress = true
        let batch = db.batch()
        this.addresses.forEach((a) => {
          // Update address
          let orderRef = db.collection('addresses').doc(a.id)
          let obj = {
            ...a
          }
          delete obj.id
          batch.update(orderRef, { ...obj })
        })
        await batch.commit()
        this.$emit('closeSidebar')
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Load Address of firestore
     * @returns {Promise<void>}
     */
    async loadAddresses () {
      try {
        this.progress = true
        this.addresses = []
        const querySnap = await db.collection('clients').doc(this.client.id).collection('addresses').get()
        querySnap.forEach((doc) => {
          let address = {
            id: doc.id,
            ...doc.data()
          }
          console.log(address)
          address.departmentName = this.departments.find(d => d.codDepartamento === address.department).nomDepartamento
          address.provinceName = this.provinces.find(p => p.codProvincia === address.province).nomProvincia
          address.districtName = this.districts.find(d => d.codDistrito === address.district).nomDistrito
          this.addresses.push(address)
        })
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 950px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
