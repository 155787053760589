// en, de, fr, pt
export default {
  es: {
    Dashboard: 'Dashboard',
    Catalogue: 'Catálogo',
    Categories: 'Categorías',
    Colors: 'Colores',
    Products: 'Productos',
    Sales: 'Ventas',
    Orders: 'Pedidos',
    Clients: 'Clientes',
    Coupons: 'Cupones',
    Delivery: 'Delivery',
    DeliveryAreas: 'Zonas de entrega',
    General: 'General',
    Users: 'Usuarios',
    Settings: 'Configuraciones',
    Sliders: 'Sliders',
    SlidersMobile: 'Sliders en móvil',
    Banners: 'Banners',
    MobileBanners: 'Banners en móvil',
    Variants: 'Presentaciones',
    Calendar: 'Calendario',
    Terms: 'T&C',
    Politics: 'Políticas de garantía',
    Faqs: 'Preguntas frecuentes',
    Promotions: 'Promociones',
    Comments: 'Comentarios',
    Claims: 'Reclamos'
  }
}
