/* eslint-disable */
export default {
    pages: {
        key: "title",
        data: [
            // DASHBOARDS
            {title: "Dashboard", url: "/dashboard/", icon: "HomeIcon", is_bookmarked: false},

            // Pages
            {title: "Categorías", url: "/catalogue/categories", icon: "BoxIcon", is_bookmarked: false},
            {title: "Características", url: "/catalogue/features", icon: "FileTextIcon", is_bookmarked: false},
            {title: "Productos", url: "/catalogue/products", icon: "StarIcon", is_bookmarked: true},
            {title: "Pedidos", url: "/sales/orders", icon: "ShoppingCartIcon", is_bookmarked: true},
            {title: "Clientes", url: "/sales/clients", icon: "UserCheckIcon", is_bookmarked: false},
            {title: "Cupones", url: "/sales/coupons", icon: "ZapIcon", is_bookmarked: false},
        ]
    },
}
/* eslint-enable */
