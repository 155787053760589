<template>
  <div>
    <div class="mb-2" v-if="initProgress" style="margin-top: 1rem; width: 100%">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="vx-row">
      <vx-card collapse="false" title="Filtros" class="user-list-filters mb-8" collapse-action>
        <div class="vx-row">
          <div class="vx-col md:w-1/2">
            <label class="vs-select--label" for="startDate">Desde</label>
            <datepicker label="Desde" v-model="startDate" id="startDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
          <div class="vx-col md:w-1/2">
            <label class="vs-select--label" for="endDate">Hasta</label>
            <datepicker label="Hasta" v-model="endDate" id="endDate"
                        name="endDate"></datepicker>
          </div>
          <div class="vx-col mt-2">
            <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
        <statistics-card-line
            v-if="quantityProducts.analyticsData"
            icon="ShoppingCartIcon"
            :statistic="quantityProducts.analyticsData.total | k_formatter"
            statisticTitle="Productos vendidos"
            :chartData="quantityProducts.series"
            type="area"/>
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
        <statistics-card-line
            v-if="totalOrders.analyticsData"
            icon="ShoppingBagIcon"
            :statistic="totalOrders.analyticsData.total | k_formatter"
            statisticTitle="Total de pedidos"
            color="warning"
            :chartData="totalOrders.series"
            type="area"/>
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
        <statistics-card-line
            v-if="revenueGenerated.analyticsData"
            icon="DollarSignIcon"
            :statistic="revenueGenerated.analyticsData.total | k_formatter"
            statisticTitle="Ingresos"
            color="success"
            :chartData="revenueGenerated.series"
            type="area"/>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-3/3 mb-base">
        <vx-card title="Ventas">
          <div slot="no-body" class="p-6 pb-0">
            <div class="flex" v-if="revenueGenerated.analyticsData">
              <div class="mr-6">
                <p class="mb-1 font-semibold">Total</p>
                <p class="text-3xl text-success"><sup class="text-base mr-1">S/</sup>{{
                    revenueGenerated.analyticsData.total
                  }}</p>
              </div>
            </div>
            <vue-apex-charts
                ref="chart1"
                type="line"
                height="266"
                :options="options"
                :series="series"/>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
        <vx-card title="Productos vendidos">
          <div slot="no-body" class="mt-4">
            <vs-table :data="soldProductsTable" class="table-dark-inverted">
              <template slot="thead">
                <vs-th>Nombre</vs-th>
                <vs-th>Cantidad</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr.name">
                    <span>{{ tr.name }}</span>
                  </vs-td>
                  <vs-td :data="tr.quantity">
                    <span>{{ tr.quantity }}</span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>

        </vx-card>
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
        <vx-card title="Categorías vendidas">
          <div slot="no-body" class="mt-4">
            <vs-table :data="soldCategoriesTable" class="table-dark-inverted">
              <template slot="thead">
                <vs-th>Nombre</vs-th>
                <vs-th>Cantidad</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr.name">
                    <span>{{ tr.name }}</span>
                  </vs-td>
                  <vs-td :data="tr.quantity">
                    <span>{{ tr.quantity }}</span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>

        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import VueApexCharts from 'vue-apexcharts'
import Datepicker from 'vuejs-datepicker'

import { db, Timestamp } from '@/firebase/firebaseConfig'
// Required for side-effects
export default {
  name: 'Home',
  components: {
    VueApexCharts,
    StatisticsCardLine,
    Datepicker
  },
  data () {
    return {
      startDate: '',
      endDate: '',
      soldProducts: [],
      soldProductsTable: [],
      soldCategoriesTable: [],
      sales: [],
      initProgress: false,
      // Charts
      quantityProducts: {
        series: [],
        analyticsData: {
          total: 0
        }
      },
      totalOrders: {
        series: [],
        analyticsData: {
          total: 0
        }
      },
      revenueGenerated: {
        series: [],
        seriesLine: [],
        analyticsData: {
          total: 0
        }
      },
      revenueComparisonLine: {
        chartOptions: {
          chart: {
            id: 'realtime',
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 5,
              left: 0,
              blur: 4,
              opacity: 0.10
            }
          },
          stroke: {
            width: [0, 4]
          },
          grid: {
            borderColor: '#e7e7e7'
          },
          colors: ['#F7323F'],
          dataLabels: {
            enabled: false,
            enabledOnSeries: [1]
          },
          labels: [],
          xaxis: {
            type: 'datetime'
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                cssClass: 'text-grey fill-current'
              },
              formatter (val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              }
            }
          },
          tooltip: {
            x: { show: true }
          }
        }
      }
    }
  },
  computed: {
    options () {
      return this.revenueComparisonLine.chartOptions
    },
    series () {
      return this.revenueGenerated.seriesLine
    }
  },
  watch: {
    series () {
      this.$refs.chart1.updateOptions({
        labels: this.options.labels
      })
      this.$refs.chart1.updateSeries([{
        data: this.series[0].data,
      }], false, true)
    }
  },
  async created () {
    try {
      this.initProgress = true
      // Dates
      const year = new Date().getFullYear()
      const month = new Date().getMonth()

      const starTimes = Timestamp.fromDate(new Date(year + '/' + (month + 1) + '/1'))
      const endTimes = Timestamp.fromDate(new Date(year + '/' + (month + 1) + '/' + this.$moment(year + '-' + (month + 1), 'YYYY-MM').daysInMonth()))

      // Get sold products
      const querySnapSold = await db.collection('soldProducts')
          .where('saleDate', '>=', starTimes)
          .where('saleDate', '<=', endTimes).get()
      querySnapSold.forEach((doc) => {
        const sold = {
          id: doc.id,
          ...doc.data(),
          saleDate: this.$moment(doc.data().saleDate.toDate()).format('YYYY-MM-DD')
        }
        this.soldProducts.push(sold)
      })
      // End ger sold products
      // Get sales
      const querySnapSales = await db.collection('sales')
          .where('saleDate', '>=', starTimes)
          .where('saleDate', '<=', endTimes).get()
      querySnapSales.forEach((doc) => {
        const sale = {
          id: doc.id,
          ...doc.data(),
          saleDate: this.$moment(doc.data().saleDate.toDate()).format('YYYY-MM-DD')
        }
        sale.total = parseFloat(sale.total)
        this.sales.push(sale)
      })
      // End get sales
      this.generateChartsData()
      this.generateChartsRevenue()
      this.orderProductAndCategories()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  methods: {
    generateChartsData () {
      // Reset
      this.quantityProducts.series = []
      this.totalOrders.series = []
      this.revenueComparisonLine.labels = []
      // Quantity products
      const serieQuantityProducts = {}
      serieQuantityProducts.name = 'Cantidad'
      serieQuantityProducts.data = []
      this.quantityProducts.analyticsData.total = this.soldProducts.reduce((total, obj) => {
        return total + obj.quantity
      }, 0)
      const salesDatesQuantityProducts = []
      this.soldProducts.forEach((p) => {
        let flag = 0
        salesDatesQuantityProducts.forEach((s) => {
          if (p.saleDate === s.saleDate) {
            flag = 1
          }
        })
        if (flag === 0) {
          salesDatesQuantityProducts.push({
            saleDate: p.saleDate,
            total: 0
          })
        }
      })
      salesDatesQuantityProducts.forEach((s) => {
        this.soldProducts.forEach((p) => {
          if (s.saleDate === p.saleDate) {
            s.total += p.quantity
          }
        })
      })
      salesDatesQuantityProducts.forEach((s) => {
        serieQuantityProducts.data.push(s.total)
      })
      this.quantityProducts.series.push(serieQuantityProducts)
      // End quantity products

      //Total orders
      const serieTotalOrders = {}
      serieTotalOrders.name = 'Cantidad'
      serieTotalOrders.data = []
      this.totalOrders.analyticsData.total = this.sales.length
      const salesDatesTotalOrders = []
      this.sales.forEach((p) => {
        let flag = 0
        salesDatesTotalOrders.forEach((s) => {
          if (p.saleDate === s.saleDate) {
            flag = 1
          }
        })
        if (flag === 0) {
          salesDatesTotalOrders.push({
            saleDate: p.saleDate,
            total: 0
          })
        }
      })
      salesDatesTotalOrders.forEach((s) => {
        this.sales.forEach((p) => {
          if (s.saleDate === p.saleDate) {
            s.total += 1
          }
        })
      })
      salesDatesTotalOrders.forEach((s) => {
        serieTotalOrders.data.push(s.total)
      })
      this.totalOrders.series.push(serieTotalOrders)
    },
    generateChartsRevenue () {
      this.revenueGenerated.series = []
      this.revenueGenerated.seriesLine = []
      // Revenue generated
      const serieRevenueGenerated = {}
      serieRevenueGenerated.name = 'Total'
      serieRevenueGenerated.data = []
      const serieRevenueGeneratedLine = {}
      serieRevenueGeneratedLine.name = 'Total'
      serieRevenueGeneratedLine.type = 'column'
      serieRevenueGeneratedLine.data = []
      const reducerRevenue = (accumulator, currentValue) => accumulator + currentValue.total
      this.revenueGenerated.analyticsData.total = parseFloat(this.sales.reduce(reducerRevenue, 0)).toFixed(2)
      const salesDatesRevenue = []
      this.sales.forEach((p) => {
        let flag = 0
        salesDatesRevenue.forEach((s) => {
          if (p.saleDate === s.saleDate) {
            flag = 1
          }
        })
        if (flag === 0) {
          salesDatesRevenue.push({
            saleDate: p.saleDate,
            total: 0
          })
        }
      })
      salesDatesRevenue.forEach((s) => {
        this.sales.forEach((p) => {
          if (s.saleDate === p.saleDate) {
            s.total += p.total
          }
        })
      })
      salesDatesRevenue.forEach((s) => {
        console.log(s)
        serieRevenueGenerated.data.push(s.total.toFixed(2))
        serieRevenueGeneratedLine.data.push(s.total.toFixed(2))
        this.revenueComparisonLine.chartOptions.labels.push(s.saleDate)
      })
      this.revenueGenerated.series.push(serieRevenueGenerated)
      this.revenueGenerated.seriesLine.push(serieRevenueGeneratedLine)
      // End revenue generated
    },
    async filterByDate () {
      try {
        this.initProgress = true
        //Reset
        this.soldProducts = []
        this.sales = []
        // Dates
        const starTimes = Timestamp.fromDate(this.startDate)
        const endTimes = Timestamp.fromDate(this.endDate)

        // Get sold products
        const querySnapSold = await db.collection('soldProducts')
            .where('saleDate', '>=', starTimes)
            .where('saleDate', '<=', endTimes).get()
        querySnapSold.forEach((doc) => {
          const sold = {
            id: doc.id,
            ...doc.data(),
            saleDate: this.$moment(doc.data().saleDate.toDate()).format('YYYY-MM-DD')
          }
          this.soldProducts.push(sold)
        })
        // End ger sold products
        // Get sales
        const querySnapSales = await db.collection('sales')
            .where('saleDate', '>=', starTimes)
            .where('saleDate', '<=', endTimes).get()
        querySnapSales.forEach((doc) => {
          const sale = {
            id: doc.id,
            ...doc.data(),
            saleDate: this.$moment(doc.data().saleDate.toDate()).format('YYYY-MM-DD')
          }
          sale.total = parseFloat(sale.total)
          this.sales.push(sale)
        })
        // End get sales
        this.generateChartsData()
        this.generateChartsRevenueFilter()
        this.orderProductAndCategories()
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    generateChartsRevenueFilter () {
      this.$refs.chart1.resetSeries()
      this.revenueGenerated.series = []
      this.revenueGenerated.seriesLine = []
      this.revenueComparisonLine.chartOptions.labels = []
      // Revenue generated
      const serieRevenueGenerated = {}
      serieRevenueGenerated.name = 'Total'
      serieRevenueGenerated.data = []
      const serieRevenueGeneratedLine = {}
      serieRevenueGeneratedLine.name = 'Total'
      serieRevenueGeneratedLine.type = 'column'
      serieRevenueGeneratedLine.data = []
      const reducerRevenue = (accumulator, currentValue) => accumulator + currentValue.total
      this.revenueGenerated.analyticsData.total = parseFloat(this.sales.reduce(reducerRevenue, 0)).toFixed(2)
      const salesDatesRevenue = []
      this.sales.forEach((p) => {
        let flag = 0
        salesDatesRevenue.forEach((s) => {
          if (p.saleDate === s.saleDate) {
            flag = 1
          }
        })
        if (flag === 0) {
          salesDatesRevenue.push({
            saleDate: p.saleDate,
            total: 0
          })
        }
      })
      salesDatesRevenue.forEach((s) => {
        this.sales.forEach((p) => {
          if (s.saleDate === p.saleDate) {
            s.total += p.total
          }
        })
      })
      salesDatesRevenue.forEach((s) => {
        serieRevenueGenerated.data.push(s.total.toFixed(2))
        serieRevenueGeneratedLine.data.push(s.total.toFixed(2))
        this.revenueComparisonLine.chartOptions.labels.push(s.saleDate)
      })
      this.revenueGenerated.series.push(serieRevenueGenerated)
      this.revenueGenerated.seriesLine.push(serieRevenueGeneratedLine)
    },
    orderProductAndCategories () {
      this.soldProductsTable = []
      this.soldCategoriesTable = []
      // Order products
      const products = []
      this.soldProducts.forEach((p) => {
        let index = products.findIndex((p2) => p2.name === p.name)
        if (index === -1) {
          let newProduct = {
            name: p.name,
            quantity: 0
          }
          products.push(newProduct)
        }
      })
      this.soldProducts.forEach((p) => {
        products.forEach((p2) => {
          if (p.name === p2.name) {
            p2.quantity += p.quantity
          }
        })
      })
      this.soldProductsTable = [...products]
      this.soldProductsTable.sort((a, b) => b.quantity - a.quantity)
      // End order products

      // Order categories
      const categories = []
      this.soldProducts.forEach((p) => {
        let index = categories.findIndex((c) => c.name === p.categoryName)
        if (index === -1) {
          let category = {
            name: p.categoryName,
            quantity: 0
          }
          categories.push(category)
        }
      })
      this.soldProducts.forEach((p) => {
        categories.forEach((c) => {
          if (p.categoryName === c.name) {
            c.quantity += p.quantity
          }
        })
      })
      this.soldCategoriesTable = [...categories]
      this.soldCategoriesTable.sort((a, b) => b.quantity - a.quantity)
    }
  }
}
</script>

<style scoped>

</style>
