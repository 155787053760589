<template>
  <vx-card title="Sliders">
    <p>Aquí se pueden modificar los Sliders de la versión mobile que aparecen en el Home.</p>

    <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <div class="mt-5">
      <!-- Sliders -->
      <div class="flex gap-4">
        <div v-for="(slider, index) in content.mobileSliders" class="w-1/3" :key="index">
          <div>
            <!-- Image Container -->
            <div class="img-container flex items-center justify-center">
              <template v-if="slider.type === 'image'">
                <img :src="`https://imagedelivery.net/Ck3pNnmzUix85yHb9uH3Hg/${slider.image}/1080x1350`"
                     alt="Hero Image"
                     class="responsive">
              </template>
              <template v-else>
                <mux-player
                    v-if="slider.video"
                    :playback-id="slider.video[0].id"
                    metadata-video-title="Hero Video"
                ></mux-player>
              </template>
            </div>
            <!--End-->
            <!-- Image upload Buttons -->
            <div class="modify-img flex justify-between mt-5">
              <input type="file" class="hidden" :id="`slider${index}`" @change="updateSlider($event,slider)"
                     accept="image/*, video/*">
              <span style="cursor: pointer" class="ml-2" type="flat"
                    @click="changeSlider(`slider${index}`)">Cambiar
                                </span>
              <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                    @click="deleteSlider(index)">Eliminar</span>
            </div>
            <!--End-->
          </div>
          <div class="mt-5">
            <vs-input @blur="slider.path= trimString(slider.path)" label="Path" v-model="slider.path"
                      class="mt-5 w-full" name="path"
                      v-validate="'required'"/>
          </div>
          <hr class="mt-5 mb-5">
      </div>
      </div>
      <!---End--->

      <!--Add image-->
      <vs-button style="width: 100%" @click="addSlider">Agregar slider (1080 x 1350)</vs-button>
      <!--End-->

      <div class="flex mt-6 flex-wrap items-center" slot="footer">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
        <div v-if="progress" style="margin-top: 1rem; width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import trimString from '../mixins/trimString'
import { auth, db, FieldValue } from '@/firebase/firebaseConfig'

import axios from "axios"
import * as UpChunk from "@mux/upchunk"

export default {
  name: 'Sliders',
  mixins: [
    trimString
  ],
  data () {
    return {
      content: {
        mobileSliders: []
      },
      progress: false,
      initProgress: false
    }
  },
  /**
   * Get data on firestore
   * @returns {Promise<void>}
   */
  async created () {
    try {
      this.initProgress = true
      const querySnapshot = await db.collection('content').get()
      querySnapshot.forEach((doc) => {
        this.content.id = doc.id
        this.content.mobileSliders = doc.data().mobileSliders || []
      })
    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  computed: {
    isFormValid () {
      return !this.errors.any()
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true

          // Content
          let contentRef = db.collection('content').doc(this.content.id)

          //Clone to remove the id
          let cloneContent = Object.assign({}, this.content)
          delete cloneContent.id

          await contentRef.update({
            ...cloneContent,
            uid: auth.currentUser.uid,
            updatedAt: FieldValue.serverTimestamp()
          })

          this.$vs.notify({
            color: 'success',
            title: 'Sliders',
            text: 'Sliders modificados correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Update slider
     * @param input
     * @param slider
     * @returns {Promise<void>}
     */
    async updateSlider (input, slider) {
      try {
        this.progress = true
        if (input.target.files && input.target.files[0]) {
          console.log(input.target.files[0].type)
          if (input.target.files[0].type.startsWith('image/')) {
            const uploadUrl = await this.generateUploadImageOfCloudFlare()
            slider.type = 'image'
            slider.image = await this.submitImages(input.target.files[0], uploadUrl)
            this.progress = false
          }
          else if (input.target.files[0].type === 'video/mp4') {
            const uploadData = await this.generateUploadUrlOfMux()
            slider.type = 'video'
            await this.submitVideo(input.target.files[0], uploadData.url, uploadData.id, slider)
          }
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
        this.progress = false
      }
    },
    /**
     * Delete slider
     * @param index
     */
    deleteSlider (index) {
      this.content.mobileSliders.splice(index, 1)
    },
    /**
     * Save images in firebase storage
     * @param id
     * @returns {Promise<unknown>}
     */
    changeSlider (id) {
      const slider = document.getElementById(id)
      slider.click()
    },
    /**
     * Add slider
     */
    addSlider () {
      let obj = {
        type: 'image',
        path: '/',
        image: 'https://placehold.co/1920x1080'
      }
      this.content.mobileSliders.push(obj)
    },
    /**
     * Generate upload url for video
     * @returns {Promise<*>}
     */
    async generateUploadUrlOfMux () {
      const response = await axios.get('https://southamerica-east1-chronicles-9f908.cloudfunctions.net/mux-generateUploadLink', {
        headers: {
          Authorization: 'Bearer 46ee87d8-5f2c-470f-91e7-3457189b9079'
        }
      })
      return response.data.responseMux
    },
    /**
     * Save image in cloud flare
     * @param file
     * @param uploadUrl
     * @param uploadId
     * @param slider
     * @returns {Promise<unknown>}
     */
    submitVideo (file, uploadUrl, uploadId, slider) {
      const upload = UpChunk.createUpload({
        endpoint: uploadUrl,
        file: file,
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      })

      // Watch upload done
      upload.on('success', async () => {

        // Get playback id
        setTimeout(async () => {
          const response = await axios.get('https://southamerica-east1-chronicles-9f908.cloudfunctions.net/mux-getPlaybackId?uploadId=' + uploadId, {
            headers: {
              Authorization: 'Bearer 46ee87d8-5f2c-470f-91e7-3457189b9079'
            }
          })
          slider.video = response.data.playback_ids
          this.progress = false
        })
      }, 30000)
      // End
    },
    /**
     * Save image in firebase storage
     * @param image
     * @param uploadUrl
     * @returns {Promise<unknown>}
     */
    async submitImages (image, uploadUrl) {
      const form = new FormData()
      form.append("file", image)

      const options = {
        method: 'POST',
        url: uploadUrl,
        headers: {
          'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001'
        },
        data: form
      }

      const response = await axios.request(options)

      return response.data.result.id
    },
    /**
     * Generate upload image
     * @returns {Promise<*>}
     */
    async generateUploadImageOfCloudFlare () {
      const response = await axios.get('https://southamerica-east1-chronicles-9f908.cloudfunctions.net/cloudFlare-generateUploadLink', {
        headers: {
          Authorization: 'Bearer 46ee87d8-5f2c-470f-91e7-3457189b9079'
        }
      })
      return response.data.data.result.uploadURL
    }
  }
}
</script>
