<template>
  <vx-card title="Contenido">
    <p>Políticas de garantía</p>

    <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <div class="mt-5">
      <quill-editor v-model="content.politics"></quill-editor>
    </div>

    <!---->
    <div class="flex mt-6 flex-wrap items-center" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vx-card>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import trimString from '../mixins/trimString'

import { db, FieldValue } from '@/firebase/firebaseConfig'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'Politics',
  mixins: [
    trimString
  ],
  data () {
    return {
      content: {
        id: null,
        politics: ''
      },
      progress: false,
      initProgress: false
    }
  },
  components: {
    quillEditor
  },
  /**
   * Get data of firestore
   * @returns {Promise<void>}
   */
  async created () {
    try {
      this.initProgress = true
      const querySnapshot = await db.collection('content').get()
      querySnapshot.forEach((doc) => {
        this.content.id = doc.id
        this.content.politics = doc.data().politics
      })
    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  computed: {
    isFormValid () {
      return !this.errors.any()
    }
  },
  methods: {
    /**
     * Save data on firestore
     */
    async submitData () {
      try {
        const result = this.$validator.validateAll()
        if (result) {
          this.progress = true
          // Content
          let contentRef = db.collection('content').doc(this.content.id)

          // Clone to remove the id
          let cloneContent = Object.assign({}, this.content)
          delete cloneContent.id

          await contentRef.update({
            ...cloneContent,
            updatedAt: FieldValue.serverTimestamp()
          })

          this.$vs.notify({
            color: 'success',
            title: 'Políticas de garantía',
            text: 'La modificación fue aplicada.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>
